// extracted by mini-css-extract-plugin
export var firstImage = "PostCard-module--firstImage--IokRl";
export var innerContainer = "PostCard-module--innerContainer--2wR-Z";
export var grid = "PostCard-module--grid--1UWu_";
export var grid2 = "PostCard-module--grid2--1QOWx";
export var anchorStyles = "PostCard-module--anchorStyles--2wOG7";
export var card = "PostCard-module--card--1Z05R";
export var firstCard = "PostCard-module--firstCard--1Rq-v";
export var firstCardTitle = "PostCard-module--firstCardTitle--TkyIs";
export var excerpt = "PostCard-module--excerpt--2vw4Y";
export var postsGap = "PostCard-module--postsGap--91xkQ";
export var postImage = "PostCard-module--postImage--1oisr";
export var taxomonies = "PostCard-module--taxomonies--3CxPL";
export var link = "PostCard-module--link--1RG03";
export var date = "PostCard-module--date--UvO9l";
export var title = "PostCard-module--title--2EnTP";
export var titleLink = "PostCard-module--titleLink--2NyRI";